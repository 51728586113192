import React, { useMemo, useState } from 'react';
import ErrorComponent from '@components/error/ErrorComponent';
import Loading from '@components/loading/Loading';
import { Player } from '@beatport/ui/dist/cjs/Player';
import { Button } from '@beatport/ui/dist/cjs/Buttons';
import ErrorBoundary from '@components/error/ErrorBoundary';
import './App.btsrc.scss';
import useCatalog from 'hooks/useCatalog';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import IconShareAlt from '@components/icons/ShareAlt';
import IconShoppingCart from '@components/icons/ShoppingCart';
import { makeBeatportUrl } from '@utils/urls';
import { TrackList } from '@beatport/ui/dist/cjs/Tracks'
import { Modal } from '@beatport/ui/dist/cjs/Modal';
import IconFacebook from '@components/icons/Facebook';
import IconTwitter from '@components/icons/Twitter';
import BeatsourceLogo from '@components/icons/BeatsourceLogo';

import * as THEME from '../../theme/btsrc';

const player = React.createRef();
window.BeatportEmbed = window.BeatportEmbed || {}
window.BeatportEmbed.player = player;

const isNeedleDrop = false;

import { initBroadcastChannel, INSTANCE_ID } from '@utils/broadcastChannel';
import makeShareUrls from '@utils/makeShareUrls';

let channel;
try {
  // Currently this fails in Safari when embedded in an iframe
  // https://github.com/pubkey/broadcast-channel/issues/419
  //
  channel = initBroadcastChannel();
} catch (e) {
  console.warn(e);
}

const ALLOWED_TYPES = ['track', 'release', 'chart', 'playlist'];

const App: React.FC = () => {
  const [id] = useQueryParam('id', NumberParam);
  const [_artwork] = useQueryParam('artwork', NumberParam);
  const [_type] = useQueryParam('type', StringParam);
  const type = useMemo(() => !_type ? 'track' : _type, []);
  const artwork = useMemo(() => !_artwork ? 0 : _artwork, []);
  const { tracks, loading: catalogLoading, error: catalogError } = useCatalog(type, id);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  if (catalogLoading) {
    return (
      <Loading />
    )
  }

  if (catalogError) {
    return <ErrorComponent LogoComponent={BeatsourceLogo} title={catalogError.message} />
  }


  if (!id) {
    return <ErrorComponent LogoComponent={BeatsourceLogo} title={`Sorry, we couldn't find that ${type}.`} />
  }

  if (ALLOWED_TYPES.indexOf(type) === -1) {
    return <ErrorComponent LogoComponent={BeatsourceLogo} title={`Sorry, that item can't be displayed.`} />
  }

  return (
    <ErrorBoundary LogoComponent={BeatsourceLogo}>
      <div className="App">
        <header className="App__header">
          <BeatsourceLogo className="App__header-logo" />
        </header>
        <div className="App__player-container">
          <Player
            ref={player}            
            PlayerArtwork={({ playlist }) => {
              if (artwork === 0 && playlist.current) {
                return (
                  <span className="Player__artwork-2">
                    <img
                      alt={playlist.current.name}
                      key={playlist.current.uiuid}
                      id={playlist.current.uiuid}
                      src={playlist?.current?.release?.image?.uri}
                    />
                  </span>
                )
              }

              return null;
            }}
            onPlayStateChange={(status) => {
              if (channel) {
                channel.postMessage(JSON.stringify({ code: 'playstatechange', status, instance_id: INSTANCE_ID }))
              }
            }}
            renderPlayerExtra={(state) => {
              const current = state?.playlist?.current;

              if (!current) {
                return;
              }

              const { facebookShareUrl, twitterShareUrl } = makeShareUrls(current);
              const onBuyClick = () => window.open(makeBeatportUrl(current), '_blank');
              const onShareClick = () => setShareModalOpen(true);

              return (
                <>
                  <Button className="ActionButton ActionButton--buy" category="option" nomin onClick={onBuyClick}>
                    <IconShoppingCart className="ActionButton__icon" />
                    <span className="ActionButton__text">Buy on Beatport</span>
                  </Button>
                  <Button className="ActionButton ActionButton--share" category="option" nomin onClick={onShareClick}>
                    <IconShareAlt className="ActionButton__icon" />
                    <span className="ActionButton__text">Share</span>
                  </Button>
                  <Modal
                    open={shareModalOpen}
                    onClose={() => setShareModalOpen(false)}
                    showCloseButton
                  >
                    <div className="Share">
                      <Button
                        className="ShareButton"
                        size="large"
                        fullWidth
                        onClick={() => window.open(facebookShareUrl, '_blank')}
                      >
                        <IconFacebook className="ShareButton__icon" />
                        <span className="ShareButton__text">Share on Facebook</span>
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="ShareButton"
                        size="large"
                        fullWidth
                        onClick={() => window.open(twitterShareUrl, '_blank')}
                      >
                        <IconTwitter className="ShareButton__icon" />
                        <span className="ShareButton__text">Share on Twitter</span>
                      </Button>
                    </div>
                  </Modal>
                </>
              )
            }}
            waveformSize="half"
            zoomEnabled={false}
            volumeFaderOrientation="horizontal"
            waveformColorMap={{
              COLOR_TRACK_PLAYED: THEME.COLOR_TRACK_PLAYED,
              COLOR_TRACK_UNPLAYED: THEME.COLOR_TRACK_UNPLAYED,
              COLOR_TRACK_AVAILABLE: isNeedleDrop
                ? THEME.COLOR_TRACK_AVAILABLE_ND_ENABLED
                : THEME.COLOR_TRACK_AVAILABLE,
              COLOR_TRACK_AVAILABLE_BG: THEME.COLOR_TRACK_AVAILABLE_BG,
              COLOR_MARKER: THEME.COLOR_MARKER,
            }}
          />
        </div>

        {
          ['release', 'chart', 'playlist'].includes(type) && (
            <div className="App__info-container">
              <div style={{
                padding: '10px',
                width: '100%',
                height: '100%',
                background: 'rgb(207, 215, 224)',
              }}>
                <TrackList
                  showPosition={false}
                  tracks={tracks}
                  onPlayClick={async (track) => {
                    window.BeatportEmbed.player.current.playlist.addTrack(track._data);
                    window.BeatportEmbed.player.current.playlist.current = track._data;

                    await window.BeatportEmbed.player.current.loadTrack();
                    await window.BeatportEmbed.player.current.play();
                  }}
                />
              </div>
            </div>
          )
        }
      </div>
    </ErrorBoundary>
  );
}

export default App;
